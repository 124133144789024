<template>
  <div class="login-page">
    <el-card class="login-height">
      <div slot="header" style="height: 35px;">
        <div style="float: left; margin: 0 10px 0 20px">
          <img alt="" src="/img/logo_35.png"/>
        </div>
        <div style="float: left; line-height: 35px">
          <h4><strong>辽宁省华顺热力集团抚顺区域管理应用平台用户登录</strong></h4>
        </div>
      </div>
      <el-form>
        <el-form-item label="登录名">
          <el-input v-model="username" placeholder="用户名、手机号码或绑定邮箱"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input placeholder="请输入密码" v-model="password" show-password class=""></el-input>
        </el-form-item>
        <el-row>
          <el-col :span=24 class="text-left">
            <el-checkbox v-model="autoLoginChecked">保存密码</el-checkbox>
          </el-col>
        </el-row>
        <div class="blank15"></div>
        <el-button type="primary" class="btn-full" @click="login">登录</el-button>
        <div class="blank15"></div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import clientUtils from '../../js/clientUtils'
  import api from "@/js/api";

  export default {
    name: 'Login',
    data: function () {
      return {
        username: 'admin',
        password: '123456',
        passwordMd5: '',
        autoLoginChecked: false,
        keepLogin: 0,
      }
    },
    methods: {
      login: function () {
        this.passwordMd5 = clientUtils.encryptMd5(this.password);
        this.keepLogin = this.autoLoginChecked ? 1 : 0;
        const params = {
          username: this.username,
          password: this.passwordMd5,
          version: this.keepLogin,
          deleted: this.keepLogin
        };
        // 用户名密码准备完毕
        const that = this;
        clientUtils.post(api.LOGIN, params, function (res) {
          if (res.data.msg != null) {
            // 处理其它非常规错误
            clientUtils.unknownErrorAlert(that, res.data.msg);
          } else {
            if (res.data.mobile != null && res.data.password != null) {
              // 登录成功，将返回的password(token)存入localStorage
              clientUtils.loginSuccess(res.data);
              // clientUtils.OverallUserId =res.data.id
              // 跳转
              that.$router.push('/default');
            } else {
              // 登录失败，未知错误
              clientUtils.unknownErrorAlert(res.data.msg);
            }
          }
        });
      }
    },
    mounted() {
      this.$emit('setTitle', '登录')
    }
  }
</script>

<style scoped>
  .login-height {
    max-width: 550px;
    height: 410px;
  }

  .login-page {
    margin: 0;
    padding-top: calc((100vh - 450px) / 2);
    padding-left: calc((100vw - 550px) / 2);
    height: calc(100vh - 50px);
    background-image: url("/img/login_bg.jpg");
    background-size: cover;
  }
</style>
